import React, { useMemo, createContext, useState } from 'react';
import { searchFormFieldsListRentRoomOrBuildingDefault } from '../constants/rentProperty';
import { PropertySearchModeFullAccess } from '../types/propertySearchParams/mode';
import { MapZoomDefault } from '../constants/propertyList';
import { AddressesCoordinateValue } from '../types/propertySearchParams/addressesCoordinate';
import { JgdcCode } from '../types/jgdcCode';
import { useSearchFormJgdcCodes } from '../hooks/useSearchFormJgdcCodes';
import { useBool } from '../hooks/util/useBool';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';

export const RentRoomGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    rentRoomSearchSelected: Readonly<
      typeof searchFormFieldsListRentRoomOrBuildingDefault
    >;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: (
      JgdcCodes: ReadonlyArray<JgdcCode>
    ) => void;
    setRentRoomSearchSelected: (
      searchSelected: Readonly<
        typeof searchFormFieldsListRentRoomOrBuildingDefault
      >
    ) => void;
    rentRoomMode: PropertySearchModeFullAccess;
    setRentRoomMode: (mode: PropertySearchModeFullAccess) => void;
    rentRoomOffset: number;
    setRentRoomOffset: (offset: number) => void;
    rentRoomSelectedSort: SearchablePropertySort;
    setRentRoomSelectedSort: (sort: SearchablePropertySort) => void;
    isRentRoomListDrawerOpened: boolean;
    openRentRoomListDrawer: () => void;
    closeRentRoomListDrawer: () => void;
    rentRoomAddressesCoordinate: AddressesCoordinateValue;
    setRentRoomAddressesCoordinate: React.Dispatch<
      React.SetStateAction<AddressesCoordinateValue>
    >;
    rentRoomMapZoom: number;
    setRentRoomMapZoom: (mapZoom: number) => void;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const RentRoomGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [rentRoomSearchSelected, setRentRoomSearchSelected] = useState<
    Readonly<typeof searchFormFieldsListRentRoomOrBuildingDefault>
  >(searchFormFieldsListRentRoomOrBuildingDefault);
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] =
    useSearchFormJgdcCodes();
  const [rentRoomMode, setRentRoomMode] =
    useState<PropertySearchModeFullAccess>('aggregate');
  const [rentRoomOffset, setRentRoomOffset] = useState<number>(1);
  const [rentRoomSelectedSort, setRentRoomSelectedSort] =
    useState<SearchablePropertySort>('last_status_opened_at desc');
  const [
    isRentRoomListDrawerOpened,
    openRentRoomListDrawer,
    closeRentRoomListDrawer
  ] = useBool(true);
  const [rentRoomAddressesCoordinate, setRentRoomAddressesCoordinate] =
    useState<AddressesCoordinateValue>({ formSelected: {} });
  const [rentRoomMapZoom, setRentRoomMapZoom] =
    useState<number>(MapZoomDefault);

  const contextValue = useMemo(
    () => ({
      rentRoomSearchSelected,
      setRentRoomSearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentRoomMode,
      setRentRoomMode,
      rentRoomOffset,
      setRentRoomOffset,
      rentRoomSelectedSort,
      setRentRoomSelectedSort,
      isRentRoomListDrawerOpened,
      openRentRoomListDrawer,
      closeRentRoomListDrawer,
      rentRoomAddressesCoordinate,
      setRentRoomAddressesCoordinate,
      rentRoomMapZoom,
      setRentRoomMapZoom
    }),
    [
      rentRoomSearchSelected,
      setRentRoomSearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentRoomMode,
      setRentRoomMode,
      rentRoomOffset,
      setRentRoomOffset,
      rentRoomSelectedSort,
      setRentRoomSelectedSort,
      isRentRoomListDrawerOpened,
      openRentRoomListDrawer,
      closeRentRoomListDrawer,
      rentRoomAddressesCoordinate,
      setRentRoomAddressesCoordinate,
      rentRoomMapZoom,
      setRentRoomMapZoom
    ]
  );

  return (
    <RentRoomGlobalContext.Provider value={contextValue}>
      {children}
    </RentRoomGlobalContext.Provider>
  );
};
