import { OptionTypeBase } from 'react-select/src/types';

export const env = process.env.REACT_APP_ENV;

export const bbApiOrigin = process.env.REACT_APP_ITANDI_BB_API_BASE_URL;

export const GoogleFrontAPIKey = process.env.REACT_APP_GCP_API_KEY;

export type Region = Readonly<{
  name: string;
  prefectureIds: ReadonlyArray<PrefectureId>;
}>;

export const regions: ReadonlyArray<Region> = [
  { name: '北海道', prefectureIds: [1] },
  { name: '東北', prefectureIds: [2, 3, 4, 5, 6, 7] },
  { name: '関東', prefectureIds: [8, 9, 10, 11, 12, 13, 14] },
  {
    name: '中部',
    prefectureIds: [15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  { name: '近畿', prefectureIds: [24, 25, 26, 27, 28, 29, 30] },
  { name: '中国', prefectureIds: [31, 32, 33, 34, 35] },
  { name: '四国', prefectureIds: [36, 37, 38, 39] },
  {
    name: '九州',
    prefectureIds: [40, 41, 42, 43, 44, 45, 46, 47]
  }
];

export const fetchRegion = (
  prefectureId: PrefectureId | undefined
): Region | undefined => {
  if (!prefectureId) return undefined;
  return regions.find((region: Region) =>
    region.prefectureIds.includes(prefectureId)
  );
};

export const prefectures = [
  { id: 1, name: '北海道' },
  { id: 2, name: '青森県' },
  { id: 3, name: '岩手県' },
  { id: 4, name: '宮城県' },
  { id: 5, name: '秋田県' },
  { id: 6, name: '山形県' },
  { id: 7, name: '福島県' },
  { id: 8, name: '茨城県' },
  { id: 9, name: '栃木県' },
  { id: 10, name: '群馬県' },
  { id: 11, name: '埼玉県' },
  { id: 12, name: '千葉県' },
  { id: 13, name: '東京都' },
  { id: 14, name: '神奈川県' },
  { id: 15, name: '新潟県' },
  { id: 16, name: '富山県' },
  { id: 17, name: '石川県' },
  { id: 18, name: '福井県' },
  { id: 19, name: '山梨県' },
  { id: 20, name: '長野県' },
  { id: 21, name: '岐阜県' },
  { id: 22, name: '静岡県' },
  { id: 23, name: '愛知県' },
  { id: 24, name: '三重県' },
  { id: 25, name: '滋賀県' },
  { id: 26, name: '京都府' },
  { id: 27, name: '大阪府' },
  { id: 28, name: '兵庫県' },
  { id: 29, name: '奈良県' },
  { id: 30, name: '和歌山県' },
  { id: 31, name: '鳥取県' },
  { id: 32, name: '島根県' },
  { id: 33, name: '岡山県' },
  { id: 34, name: '広島県' },
  { id: 35, name: '山口県' },
  { id: 36, name: '徳島県' },
  { id: 37, name: '香川県' },
  { id: 38, name: '愛媛県' },
  { id: 39, name: '高知県' },
  { id: 40, name: '福岡県' },
  { id: 41, name: '佐賀県' },
  { id: 42, name: '長崎県' },
  { id: 43, name: '熊本県' },
  { id: 44, name: '大分県' },
  { id: 45, name: '宮崎県' },
  { id: 46, name: '鹿児島県' },
  { id: 47, name: '沖縄県' }
] as const;

export type PrefectureId = (typeof prefectures)[number]['id'];

type Preefcture = Readonly<{ id: PrefectureId; name: string }>;
export const fetchPrefecture = (prefectureId: PrefectureId): Preefcture => {
  const foundPrefecture = prefectures.find(
    (prefecture: Preefcture) => prefecture.id === prefectureId
  );
  if (!foundPrefecture) {
    throw new Error('都道府県が見つかりませんでした');
  }
  return foundPrefecture;
};

export const prefecturesOptions = [
  { value: 1, label: '北海道' },
  { value: 2, label: '青森県' },
  { value: 3, label: '岩手県' },
  { value: 4, label: '宮城県' },
  { value: 5, label: '秋田県' },
  { value: 6, label: '山形県' },
  { value: 7, label: '福島県' },
  { value: 8, label: '茨城県' },
  { value: 9, label: '栃木県' },
  { value: 10, label: '群馬県' },
  { value: 11, label: '埼玉県' },
  { value: 12, label: '千葉県' },
  { value: 13, label: '東京都' },
  { value: 14, label: '神奈川県' },
  { value: 15, label: '新潟県' },
  { value: 16, label: '富山県' },
  { value: 17, label: '石川県' },
  { value: 18, label: '福井県' },
  { value: 19, label: '山梨県' },
  { value: 20, label: '長野県' },
  { value: 21, label: '岐阜県' },
  { value: 22, label: '静岡県' },
  { value: 23, label: '愛知県' },
  { value: 24, label: '三重県' },
  { value: 25, label: '滋賀県' },
  { value: 26, label: '京都府' },
  { value: 27, label: '大阪府' },
  { value: 28, label: '兵庫県' },
  { value: 29, label: '奈良県' },
  { value: 30, label: '和歌山県' },
  { value: 31, label: '鳥取県' },
  { value: 32, label: '島根県' },
  { value: 33, label: '岡山県' },
  { value: 34, label: '広島県' },
  { value: 35, label: '山口県' },
  { value: 36, label: '徳島県' },
  { value: 37, label: '香川県' },
  { value: 38, label: '愛媛県' },
  { value: 39, label: '高知県' },
  { value: 40, label: '福岡県' },
  { value: 41, label: '佐賀県' },
  { value: 42, label: '長崎県' },
  { value: 43, label: '熊本県' },
  { value: 44, label: '大分県' },
  { value: 45, label: '宮崎県' },
  { value: 46, label: '鹿児島県' },
  { value: 47, label: '沖縄県' }
];

export const minCount = 1;

export const statusCode = {
  ok: 200,
  badRequest: 400,
  notFound: 404,
  internalServerError: 500,
  managementCompanyNotFound: 1001,
  tooManySearchResults: 1101,
  paramsIsEmpty: 1102,
  obiInfoIsNotSet: 1103,
  obiInfoHasYetToBeSet: 1103,
  cannotUseCreatingZumenRoom: 1104,
  cannotUseCreatingEstimate: 1301,
  exceedNumberCreateClient: 1402
};

export const snackbarDefaultTime = 6000;

export const snackbarDownloadTime = 3000;

export const snackbarAutoPromotionTime = 3000;

export const snackbarAccountEditTime = 2000;

export const snackbarFollowEditTime = 1500;

export const snackbarHideBufferTime = 1000;

export const answerToFreeQuestionInfoMaxLength = 255;

export const companiesListRowsPerPage = 20;

export const companiesListFollowMaxCount = 5;

export const checkBoxPdfList: Readonly<OptionTypeBase> = {
  layout_type: {
    image_2: 'レイアウト1【画像/最大2枚、備考欄/広】',
    image_7: 'レイアウト2【画像/最大7枚・備考欄/広】',
    image_12: 'レイアウト3【画像/最大12枚・備考欄/狭】'
  },
  obi_type: {
    brokerage: '仲介会社帯',
    management: '管理会社帯'
  }
};

export const PdfDefaultValues = {
  layout_type: ''
};

export const ListLimit = 30;

export const promotionHistoryListLimit = 5;

export const zishaGyoshakanBrokerageCompanyListLimit = 25;

export const csvExportPromotionBrokerageCompanyListLimit = 50;

export const ObiImageMaxHeight = 70;

export const ObiImagePermitFileType = ['image/gif', 'image/jpeg', 'image/png'];

// 見積書作成機能-追加項目の最大数：8
export const EstimateCustomOptionsMax = 8;

// 見積書作成機能-備考のの最大文字数：10000
export const EstimateNoteMax = 10000;

// 見積書作成機能-日割賃料の端数の丸め方
export const estimateRoundTypeSelect = {
  ceil: '切り上げる',
  floor: '切り捨てる',
  round: '四捨五入'
};

// 見積書作成機能-日割日数の種類
export const hiwariOptions = {
  monthly: '月ごとの日数',
  fixed_30_days: '30日固定'
};

export const maxSearchConditionCount = 20;

export const maxSearchConditionLabelLength = 50;

export const optionHeaderKey = 0;

// PDF Viewer フォント読み込みエラー回避設定
export const cMapUrl =
  'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.15.349/cmaps/';

export const OfferZumenImagePermitFileType = [
  'image/gif',
  'image/jpeg',
  'image/png'
];

export const OfferZumenPermitFileType = [
  'application/pdf',
  ...OfferZumenImagePermitFileType
];

// 画像表示ダイアログースライダー件数
export const ImageSlideCountTablet = 6;
export const ImageSlideCountMobile = 5;

// 検索系画面Drawer幅
export const ListDrawerWidth = 300;

// 許可するファイルタイプ
export const PermitFileType = [
  'application/pdf',
  'image/gif',
  'image/jpeg',
  'image/png'
];
