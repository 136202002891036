// TODO: a-z順に並び替える(root, login, logout, error404, errorは要検討)
export const urlHelper = {
  root: (): string => '/',
  login: (): string => '/login',
  logout: (): string => '/logout',
  // ヘッダーなしエラー
  error403: (): string => '/403',
  error429: (): string => '/429',
  error500: (): string => '/500',
  // ヘッダーありエラー
  error404: (): string => '/404',
  error429Private: (): string => '/error429',
  error: (): string => '/error', // 500エラーメッセージ
  top: (): string => '/top',
  itandiAccountsCallback: (): string => '/itandi_accounts_callback',
  promotionAutoSettings: (): string => '/promotion/auto_settings',
  promotionHistories: (): string => '/promotion/histories',
  promotionRentMailLogRoom: (
    mailLogId = ':mail_log_id',
    roomId = ':room_id'
  ): string => `/promotion/rent/mail_logs/${mailLogId}/rooms/${roomId}`,
  propertyLogs: (id = ':id'): string => `/properties/${id}/logs`,
  realEstateManagementCompanyEntryAvailableProperty: (
    realEstateManagementCompanyKey = ':realEstateManagementCompanyKey',
    foreignSystemsKey = ':foreignSystemsKey'
  ): string =>
    `/real_estate_management_companies/${realEstateManagementCompanyKey}/entry_available/properties/${foreignSystemsKey}`,
  realEstateManagementCompanyViewingAvailableProperty: (
    realEstateManagementCompanyKey = ':realEstateManagementCompanyKey',
    foreignSystemsKey = ':foreignSystemsKey'
  ): string =>
    `/real_estate_management_companies/${realEstateManagementCompanyKey}/viewing_available/properties/${foreignSystemsKey}`,
  rentLand: (id = ':id'): string => `/rent_lands/${id}`,
  rentLandsList: (): string => '/rent_lands/list',
  rentParking: (id = ':id'): string => `/rent_parkings/${id}`,
  rentParkingsList: (): string => '/rent_parkings/list',
  rentRoomsList: (): string => '/rent_rooms/list',
  rentListParams: (params = ':params'): string => `/rent/list/${params}`,
  rentRoomsListParams: (params = ':params'): string =>
    `/rent_rooms/list/${params}`,
  rentRoomsSearchConditions: (): string => '/rent_rooms/search_conditions',
  rentBusinessPropertiesList: (): string => '/rent_business_properties/list',
  rentBusinessRoomsDetail: (id = ':id'): string => `/rent_business_rooms/${id}`,
  rentBusinessBuildingsDetail: (id = ':id'): string =>
    `/rent_business_buildings/${id}`,
  followeeRentRoomsList: (): string => '/followee/rent_rooms/list',
  rentDetail: (id = ':id'): string => `/rent/detail/${id}`,
  rentRoomsDetail: (id = ':id'): string => `/rent_rooms/${id}`,
  roomBuildingLogs: (id = ':id'): string => `/room_buildings/${id}/logs`,
  roomViewingDetail: (id = ':id'): string => `/room_viewing/detail/${id}`,
  roomViewingDetailFromNew: (id = ':id'): string =>
    `/room_viewing/detail/${id}?from_new=true`,
  roomViewingList: (): string => '/room_viewing/list',
  roomViewingNew: (id = ':id'): string => `/room_viewing/new/${id}`,
  saleLand: (id = ':id'): string => `/sale_lands/${id}`,
  saleLandsList: (): string => '/sale_lands/list',
  saleRoomsDetail: (id = ':id'): string => `/sale_rooms/${id}`,
  saleRoomsList: (): string => '/sale_rooms/list',
  saleRoomsListParams: (params = ':params'): string =>
    `/sale_rooms/list/${params}`,
  saleBusinessPropertiesList: (): string => '/sale_business_properties/list',
  saleBusinessRoomsDetail: (id = ':id'): string => `/sale_business_rooms/${id}`,
  saleBusinessBuildingsDetail: (id = ':id'): string =>
    `/sale_business_buildings/${id}`,
  followNewArrivalRooms: (): string => '/follow/new_arrival_rooms/',
  followSetting: (): string => '/follow/setting/',
  accountSetting: (): string => '/account/setting/',
  zishaGyoshakanBrokerageCompanies: (): string =>
    `/zisha_gyoshakan/brokerage_companies`,
  zishaGyoshakanRentRoomsList: (key = ':zisha_gyoshakan_master_key'): string =>
    `/zisha_gyoshakan/${key}/rent_rooms/list`,
  zishaGyoshakanRentRoomsDetail: (
    key = ':zisha_gyoshakan_master_key',
    id = ':id'
  ): string => `/zisha_gyoshakan/${key}/rent_rooms/${id}`,
  zishaGyoshakanRentBusinessPropertiesList: (
    key = ':zisha_gyoshakan_master_key'
  ): string => `/zisha_gyoshakan/${key}/rent_business_properties/list`,
  zishaGyoshakanRentBusinessRoomsDetail: (
    key = ':zisha_gyoshakan_master_key',
    id = ':id'
  ): string => `/zisha_gyoshakan/${key}/rent_business_rooms/${id}`,
  zishaGyoshakanRentBusinessBuildingsDetail: (
    key = ':zisha_gyoshakan_master_key',
    id = ':id'
  ): string => `/zisha_gyoshakan/${key}/rent_business_buildings/${id}`,
  csvExportPromotionRooms: (): string => `/csv_export_promotion/rent_rooms`,
  csvExportPromotionUsers: (): string => `/csv_export_promotion/users`,
  csvExportPromotionHistories: (): string => `/csv_export_promotion/histories`
};

const bkBaseUrl = process.env.REACT_APP_BUKKAKUN_BASE_URL;
export const bkUrlHelper = {
  announceSettings: (): string => `${bkBaseUrl}/announce_settings`,
  buildingViewingSettings: (): string =>
    `${bkBaseUrl}/building_viewing_settings`,
  callers: (): string => `${bkBaseUrl}/callers`,
  companies: (): string => `${bkBaseUrl}/companies`,
  heyadomeNew: (id = ':id'): string =>
    `${bkBaseUrl}/cloud_chintai/rooms/${id}/heyadome/new`,
  information: (): string => `${bkBaseUrl}/information`,
  receiveCallLogs: (): string => `${bkBaseUrl}/receive_call_logs`,
  receiveCallLogsAnalytics: (): string =>
    `${bkBaseUrl}/receive_call_logs/analytics`,
  roomRanking: (): string => `${bkBaseUrl}/room_ranking`,
  roomViewingLogs: (): string => `${bkBaseUrl}/room_viewing_logs`,
  roomViewingLogsAnalytics: (): string =>
    `${bkBaseUrl}/room_viewing_logs/analytics`,
  roomViewingNotificationEmails: (): string =>
    `${bkBaseUrl}/room_viewing_notification_emails`,
  roomViewings: (): string => `${bkBaseUrl}/room_viewings`,
  selectApply: (id = ':id'): string =>
    `${bkBaseUrl}/cloud_chintai/rooms/${id}/select_apply`,
  viewingCustomerInformation: (): string =>
    `${bkBaseUrl}/viewing_customer_information`,
  viewingQuestionnaires: (): string => `${bkBaseUrl}/viewing_questionnaires`,
  whiteListGroups: (): string => `${bkBaseUrl}/white_list_groups`,
  edit: (): string => `${bkBaseUrl}/cloud_chintai/x_store/edit`
};

const muBaseUrl = process.env.REACT_APP_MU_BASE_URL;
export const muUrlHelper = {
  appBrokerPropertyEntryTokensNew: (propertyId: string): string =>
    `${muBaseUrl}/app/broker/properties/${propertyId}/entry_tokens/new`
};

export const supportHelper = {
  endOfSupportIe: (): string =>
    'https://support.itandi.co.jp/hc/ja/articles/4410001124499'
};

export const itandiBbSupportHelper = {
  helpManagementCompany: (): string =>
    'https://itandibb.support.itandi.co.jp/hc/ja'
};

export const itandiBbBrokerSupportHelper = {
  articleInvoice: (): string =>
    'https://itandibb-broker.support.itandi.co.jp/hc/ja/articles/20981857533075',
  helpBroker: (): string => 'https://itandibb-broker.support.itandi.co.jp/hc/ja'
};

export const orangeRibbonUrl = (): string =>
  `https://${process.env.REACT_APP_ORANGE_RIBBON_URL}`;

export const daiwalivingUrlHelper = {
  document: (): string => 'https://daiwa-living-6.rnp.njc-web.info/',
  privacyPolicy: (): string =>
    'https://www.daiwaliving.co.jp/privacy/privacy_policy/',
  dPartnerContact: (accountKey: string): string =>
    `https://docs.google.com/forms/d/e/1FAIpQLScVNagKq0ySH_sRs4cPs6w3y17VbKjwwo4FlCc0P_eR3jFGRw/viewform?entry.1457522916=${accountKey}`
};
