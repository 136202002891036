import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { LoadingPage } from '../Loading';
import { ZishaGyoshakanParams } from '../../../types/zishaGyoshakan';
import { ZishaGyoshakanForbiddenContent } from '../../parts/Common/ZishaGyoshanakForbiddenContent';
import { useNotifications } from '../../../hooks/useNotifications';
import { BrokerageCompanyHeader } from '../../parts/Common/BrokerageCompanyHeader/BrokerageCompanyHeader';
import { BrokerageCompanyMain } from '../../BrokerageCompany/Main';
import { useAccount } from '../../../hooks/useAccount';
import { ZishaGyoshakanForbiddenDaiwaContent } from '../../parts/Common/ZishaGyoshanakForbiddenDaiwaContent';
import { useZishaGyoshakanBrokerageCompanyContact } from '../../../hooks/useZishaGyoshakanBrokerageCompanyContact';
import { useTrigger } from '../../../hooks/util/useTrigger';

const useStyles = makeStyles((theme: Theme) => ({
  Body: {
    backgroundColor: theme.palette.common.white
  }
}));

export const ForbiddenZishaGyoshakanBrokerageCompanyPage: React.FC = () => {
  const { zisha_gyoshakan_master_key } = useParams<ZishaGyoshakanParams>();

  const [notificationsBadgeCountTrigger, toggleNotificationsBadgeCountTrigger] =
    useTrigger();
  const [notifications, notificationsBadgeCount] = useNotifications(
    notificationsBadgeCountTrigger
  );

  const [
    zishaGyoshakanBrokerageCompanyContact,
    loadedZishaGyoshakanBrokerageCompanyContact
  ] = useZishaGyoshakanBrokerageCompanyContact(
    zisha_gyoshakan_master_key,
    zisha_gyoshakan_master_key !== 'daiwaliving'
  );

  const [account, loadedAccount] = useAccount(
    zisha_gyoshakan_master_key === 'daiwaliving'
  );

  const classes = useStyles();

  if (!loadedZishaGyoshakanBrokerageCompanyContact && !loadedAccount) {
    return <LoadingPage />;
  }

  return (
    <div className={classes.Body}>
      <BrokerageCompanyHeader
        notifications={notifications}
        notificationsBadgeCount={notificationsBadgeCount}
        toggleNotificationsBadgeCountTrigger={
          toggleNotificationsBadgeCountTrigger
        }
        isZishaGyoshakan
        activeMenu="rentRoom"
      />
      <BrokerageCompanyMain>
        {zisha_gyoshakan_master_key === 'daiwaliving' ? (
          <ZishaGyoshakanForbiddenDaiwaContent account={account} />
        ) : (
          <ZishaGyoshakanForbiddenContent
            zishaGyoshakanContact={zishaGyoshakanBrokerageCompanyContact}
          />
        )}
      </BrokerageCompanyMain>
    </div>
  );
};
