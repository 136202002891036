import React, { useContext, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll/modules';
import { ItandiHeader, ItandiHeaderItem } from '@itandi/itandi-bb-ui';
import { useParams } from 'react-router-dom';
import { HeaderContext } from '../../../../context/HeaderContext';
import { AccountMenu } from './AccountMenu';
import { RentMenu } from './RentMenu';
import { SaleMenu } from './SaleMenu';
import { FollowMenu } from './FollowMenu';
import {
  itandiBbBrokerSupportHelper,
  urlHelper
} from '../../../../constants/common/url/urlHelpers';
import { Notification } from '../../../../types/notification';
import { NotificationItem } from './NotificationItem';
import { useZishaGyoshakanMasterLogoImage } from '../../../../hooks/useZishaGyoshakanMasterLogoImage';
import { ZishaGyoshakanParams } from '../../../../types/zishaGyoshakan';

export const headerHeightPixel = 44;

export type BrokerageCompanyHeaderActiveMenu =
  | 'rent'
  | 'sale'
  | 'follow'
  | 'roomViewing'
  | 'rentRoom'
  | 'rentBusinessProperties';

type Props = Readonly<{
  // NOTE: BB-1665ででお知らせをヘッダーに戻す際に使用するので残す
  notifications: ReadonlyArray<Notification>;
  notificationsBadgeCount: number;
  toggleNotificationsBadgeCountTrigger: () => void;
  isZishaGyoshakan: boolean;
  activeMenu: BrokerageCompanyHeaderActiveMenu;
  headerType?: 'bb' | 'broker-nk';
}>;

const HeaderMenus = (
  isZishaGyoshakan: boolean,
  zishaGyoshakanMasterKey: string,
  activeMenu: BrokerageCompanyHeaderActiveMenu,
  headerType: 'bb' | 'broker-nk'
): JSX.Element => {
  if (isZishaGyoshakan) {
    return (
      <>
        <ItandiHeaderItem
          label="賃貸居住用"
          url={urlHelper.zishaGyoshakanRentRoomsList(zishaGyoshakanMasterKey)}
          active={activeMenu === 'rentRoom'}
        />
        <ItandiHeaderItem
          label="賃貸事業用"
          url={urlHelper.zishaGyoshakanRentBusinessPropertiesList(
            zishaGyoshakanMasterKey
          )}
          active={activeMenu === 'rentBusinessProperties'}
        />
      </>
    );
  }
  if (headerType === 'bb') {
    return (
      <>
        <RentMenu isActive={activeMenu === 'rent'} />
        <SaleMenu isActive={activeMenu === 'sale'} />
        <FollowMenu isActive={activeMenu === 'follow'} />
      </>
    );
  }

  return (
    <ItandiHeaderItem
      label="内見予約管理"
      url={urlHelper.roomViewingList()}
      active
    />
  );
};

export const BrokerageCompanyHeader: React.FC<Props> = ({
  notifications,
  notificationsBadgeCount,
  toggleNotificationsBadgeCountTrigger,
  isZishaGyoshakan,
  activeMenu,
  headerType = 'bb'
}: Props) => {
  const { headerProps } = useContext(HeaderContext);
  const params = useParams<ZishaGyoshakanParams>();
  const zishaGyoshakanMasterKey = params.zisha_gyoshakan_master_key;

  const logoImageUrl = useZishaGyoshakanMasterLogoImage(
    isZishaGyoshakan,
    params.zisha_gyoshakan_master_key
  );

  useEffect(() => {
    scroll.scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ItandiHeader
      handleLogout={(): void => {
        window.location.href = urlHelper.logout();
      }}
      isAdmin={false}
      itandiAccount={{ companyName: headerProps.company_name, storeName: '' }}
      productAccountMenu={<AccountMenu />}
      productCode={headerType}
      productParams={headerProps.product_menus}
      useZennichiLogo={headerProps.is_zennichi}
      specificLogoUrl={logoImageUrl}
    >
      {HeaderMenus(
        isZishaGyoshakan,
        zishaGyoshakanMasterKey,
        activeMenu,
        headerType
      )}
      <ItandiHeaderItem
        label="ヘルプ"
        url={itandiBbBrokerSupportHelper.helpBroker()}
        isExternalLink
      />
      <NotificationItem
        notifications={notifications}
        notificationsBadgeCount={notificationsBadgeCount}
        toggleNotificationsBadgeCountTrigger={
          toggleNotificationsBadgeCountTrigger
        }
      />
    </ItandiHeader>
  );
};
