import React, { useMemo, createContext, useState } from 'react';
import { PropertySearchModeFullAccess } from '../types/propertySearchParams/mode';
import { MapZoomDefault } from '../constants/propertyList';
import { searchFormFieldsListFolloweeRent } from '../constants/followee';
import { AddressesCoordinateValue } from '../types/propertySearchParams/addressesCoordinate';
import { JgdcCode } from '../types/jgdcCode';
import { useBool } from '../hooks/util/useBool';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';

export const FolloweeRentRoomGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    followeeRentRoomSearchSelected: Readonly<
      typeof searchFormFieldsListFolloweeRent
    >;
    setFolloweeRentRoomSearchSelected: (
      searchSelected: Readonly<typeof searchFormFieldsListFolloweeRent>
    ) => void;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: (
      jgdcCodes: ReadonlyArray<JgdcCode>
    ) => void;
    followeeRentRoomMode: PropertySearchModeFullAccess;
    setFolloweeRentRoomMode: (mode: PropertySearchModeFullAccess) => void;
    followeeRentRoomOffset: number;
    setFolloweeRentRoomOffset: (offset: number) => void;
    followeeRentRoomSelectedSort: SearchablePropertySort;
    setFolloweeRentRoomSelectedSort: (sort: SearchablePropertySort) => void;
    isFolloweeRentRoomListDrawerOpened: boolean;
    openFolloweeRentRoomListDrawer: () => void;
    closeFolloweeRentRoomListDrawer: () => void;
    followeeRentRoomAddressesCoordinate: AddressesCoordinateValue;
    setFolloweeRentRoomAddressesCoordinate: (
      addressCoordinate: AddressesCoordinateValue
    ) => void;
    followeeRentRoomMapZoom: number;
    setFolloweeRentRoomMapZoom: (mapZoom: number) => void;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const FolloweeRentRoomGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [followeeRentRoomSearchSelected, setFolloweeRentRoomSearchSelected] =
    useState<Readonly<typeof searchFormFieldsListFolloweeRent>>(
      searchFormFieldsListFolloweeRent
    );
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] = useState<
    ReadonlyArray<JgdcCode>
  >([]);
  const [followeeRentRoomMode, setFolloweeRentRoomMode] =
    useState<PropertySearchModeFullAccess>('aggregate');
  const [followeeRentRoomOffset, setFolloweeRentRoomOffset] =
    useState<number>(1);
  const [followeeRentRoomSelectedSort, setFolloweeRentRoomSelectedSort] =
    useState<SearchablePropertySort>('last_status_opened_at desc');
  const [
    isFolloweeRentRoomListDrawerOpened,
    openFolloweeRentRoomListDrawer,
    closeFolloweeRentRoomListDrawer
  ] = useBool(true);
  const [
    followeeRentRoomAddressesCoordinate,
    setFolloweeRentRoomAddressesCoordinate
  ] = useState<AddressesCoordinateValue>({ formSelected: {} });
  const [followeeRentRoomMapZoom, setFolloweeRentRoomMapZoom] =
    useState<number>(MapZoomDefault);

  const contextValue = useMemo(
    () => ({
      followeeRentRoomSearchSelected,
      setFolloweeRentRoomSearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      followeeRentRoomMode,
      setFolloweeRentRoomMode,
      followeeRentRoomOffset,
      setFolloweeRentRoomOffset,
      followeeRentRoomSelectedSort,
      setFolloweeRentRoomSelectedSort,
      isFolloweeRentRoomListDrawerOpened,
      openFolloweeRentRoomListDrawer,
      closeFolloweeRentRoomListDrawer,
      followeeRentRoomAddressesCoordinate,
      setFolloweeRentRoomAddressesCoordinate,
      followeeRentRoomMapZoom,
      setFolloweeRentRoomMapZoom
    }),
    [
      followeeRentRoomSearchSelected,
      setFolloweeRentRoomSearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      followeeRentRoomMode,
      setFolloweeRentRoomMode,
      followeeRentRoomOffset,
      setFolloweeRentRoomOffset,
      followeeRentRoomSelectedSort,
      setFolloweeRentRoomSelectedSort,
      isFolloweeRentRoomListDrawerOpened,
      openFolloweeRentRoomListDrawer,
      closeFolloweeRentRoomListDrawer,
      followeeRentRoomAddressesCoordinate,
      setFolloweeRentRoomAddressesCoordinate,
      followeeRentRoomMapZoom,
      setFolloweeRentRoomMapZoom
    ]
  );

  return (
    <FolloweeRentRoomGlobalContext.Provider value={contextValue}>
      {children}
    </FolloweeRentRoomGlobalContext.Provider>
  );
};
