import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { errorMsg } from '../../../constants/common/msgConstant';
import { GlobalContext } from '../../../context/GlobalContext';

const useStyles = makeStyles(() => ({
  ButtonsWrapper: {
    margin: '16px',
    padding: '16px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  ButtonWrapper: {
    margin: '16px'
  },
  // Footer component内で呼ばれている、BaseStyleのstyleの書き方に依存しているので注意
  FooterWrapper: {
    '& > *': {
      position: 'static'
    }
  }
}));

type Props = Readonly<{
  title: string;
  subtitle: string;
  buttonAttributes: ReadonlyArray<{ text: string; href: string }>;
}>;

// エラー（メッセージ等表示）
export const ErrorMessageArea: React.FC<Props> = ({
  title,
  subtitle,
  buttonAttributes
}: Props): JSX.Element => {
  const classes = useStyles();
  const { isMobile } = useContext(GlobalContext);

  return (
    <Container maxWidth="lg">
      <Typography align="center" gutterBottom variant="h3">
        {title}
      </Typography>
      <Typography align="center" variant="h5">
        {subtitle}
      </Typography>
      <div
        className={classes.ButtonsWrapper}
        style={isMobile ? { flexDirection: 'column' } : {}}
      >
        {buttonAttributes.map(
          (
            buttonAttribute: Readonly<{ text: string; href: string }>,
            index
          ) => (
            <div
              className={classes.ButtonWrapper}
              // eslint-disable-next-line react/no-array-index-key
              key={`error-button-key-${index}`}
            >
              <Button
                color="primary"
                component={Link}
                size="large"
                to={{ pathname: buttonAttribute.href }}
                variant="outlined"
              >
                {buttonAttribute.text}
              </Button>
            </div>
          )
        )}
      </div>
    </Container>
  );
};

ErrorMessageArea.defaultProps = {
  title: errorMsg.ms500,
  subtitle: errorMsg.sub500
};
